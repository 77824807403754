Vue.component('save-svg', {
  template: `
     <img :src="fullurl" @error="png = true" :alt="alt"></img>
  `,
  data: function () {
    return {
      basePath: '/skin/frontend/mediadivision/oz/img/',
      png: false
    }
  },
  props: [
    'img',
    'alt'
  ],
  computed: {
    'fullurl': function() {
      var ext = this.png?'.png':'.svg';
      return this.basePath + this.img + ext;
    }
  }
})